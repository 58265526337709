import { Component, Input, ViewChild } from '@angular/core';
import { IonContent, NavParams, ModalController } from '@ionic/angular';
import * as moment from 'moment';

import { Events } from '../../providers/events';
import { ScheduleService } from '../../providers/schedule-service';
import { DateService } from '../../providers/date-service';

import { eventScheduleTypeOther } from '../../app/global';

@Component({
  selector: 'add-schedule-modal',
  templateUrl: 'add-schedule.modal.html',
  styleUrls: ['add-schedule.modal.scss']
})

export class AddScheduleModalComponent {

  @ViewChild(IonContent) content: IonContent;
  @Input() guestProps: any;

  schedule = this.navParams.get('schedule');
  eventId = this.schedule?.eventId;
  scheduleId = this.schedule?.id;
  updateSchedule = this.schedule?.updateSchedule ? this.schedule?.updateSchedule : false;
  chosenScheduleTypesValue = this.schedule?.chosenScheduleTypesValue ? this.schedule?.chosenScheduleTypesValue : {type: '', id: 0};
  otherInputValue = this.schedule?.scheduleTypeOther ? this.schedule?.scheduleTypeOther : '';
  startDateTime = this.schedule?.scheduleDateTime ? this.schedule?.scheduleDateTime : '';
  endTime = this.schedule?.endsAt ? this.schedule?.endsAt : '';
  scheduleInfo = this.schedule?.info ? this.schedule?.info : '';

  scheduleTypes: any;

  showScheduleTypes = false;

  constructor(
    private modalControl: ModalController,
    private scheduleService: ScheduleService,
    private dateService: DateService,
    public navParams: NavParams,
    public events: Events
  ) {}

  ionViewWillEnter() {
    this.fetchScheduleTypes();
  }

  async fetchScheduleTypes() {
    this.scheduleTypes = await this.scheduleService.fetchScheduleTypeValues();
    if (this.chosenScheduleTypesValue.id === eventScheduleTypeOther) {
      const otherType = this.scheduleTypes.find(type => type.id === eventScheduleTypeOther);
      this.chosenScheduleTypesValue.type = otherType.name;
    }
  }

  async createNewSchedule() {
    const newScheduleData = {
      eventId: this.eventId,
      eventScheduleTypeId: this.chosenScheduleTypesValue.id,
      scheduleTypeOther: this.otherInputValue ? this.otherInputValue : '',
      scheduleDate: moment(this.startDateTime).format('YYYY-MM-DD'),
      startsAt: this.dateService.getTime(this.startDateTime),
      endsAt: this.endTime,
      info: this.scheduleInfo ? this.scheduleInfo : ''
    };

    const newSchedule = await this.scheduleService.newSchedule(newScheduleData);
    if (newSchedule) {
      this.events.publish('modal:schedule-add-changed');
      return await this.modalControl.dismiss();
    }
  }

  async updateSchedules() {
    const updateScheduleData = {
      eventId: this.eventId,
      eventScheduleTypeId: this.chosenScheduleTypesValue.id,
      scheduleTypeOther: this.otherInputValue ? this.otherInputValue : '',
      scheduleDate: moment(this.startDateTime).format('YYYY-MM-DD'),
      startsAt: this.dateService.getTime(this.startDateTime),
      endsAt: this.endTime,
      info: this.scheduleInfo ? this.scheduleInfo : '',
      id: this.scheduleId
    };

    const updatedSchedule = await this.scheduleService.updateSchedule(updateScheduleData);
    if (updatedSchedule) {
      this.events.publish('modal:schedule-add-changed');
      return await this.modalControl.dismiss();
    }
  }

  async closeModal() {
    this.events.publish('modal:cancel');
    return await this.modalControl.dismiss();
  }

  showScheduleTypesValues() {
    this.showScheduleTypes = !this.showScheduleTypes;
  }

  chooseScheduleType(type, id) {
    this.chosenScheduleTypesValue = { type, id };
    if (this.chosenScheduleTypesValue.id) {
      this.showScheduleTypes = false;
    }
  }

  scheduleTypeOther() {
    return this.chosenScheduleTypesValue.id === eventScheduleTypeOther;
  }
}
