import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Events } from './events';

import { NavDataService } from './nav-data-service';

import { environment } from '../environments/environment';
import { expenseStatusNoExpenses, expenseStatusHasExpenses } from '../app/global';

@Injectable()
export class EventsService {

  constructor(
    public http: HttpClient,
    public navDataService: NavDataService,
    public router: Router,
    public events: Events
  ) {

  }

  async fetchEvents(fetchHistory: boolean, page: number) {
    const url = environment.baseApiUrl + `events`;

    const options = {
      params: {
        'page[number]': page,
        show_history: fetchHistory
      }
    };

    try {
      return await this.http.get(url, options).toPromise();
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async reportExpenseStatus(eventId, personId, expenseStatus) {
    const url = environment.baseApiUrl + `events/${eventId}/event_persons/${personId}`;

    const body = {
      event_person: {
        expense_report_status: expenseStatus
      }
    };

    try {
      const events: any = await this.http.put(url, body).toPromise();
      return events;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async expenseReportHasExpenses(pageName: string, event) {
    const eventId = event?.id;
    const personId = event?.event_person?.person_id;
    const artistId = event?.artist?.id;
    const eventPersonId = event?.event_person?.id;

    const reportStatusData = await this.reportExpenseStatus(eventId, eventPersonId, expenseStatusHasExpenses);
    if (reportStatusData) {
      this.events.publish(`update:${pageName}`, {...reportStatusData, eventId});

      const expensesData = {
        eventId,
        personId,
        artistId
      };
      this.navDataService.setNavParams({expensesData, event});
      return this.router.navigate(['/add-expense']);
    }
  }

  async expenseReportNone(pageName: string, event) {
    const eventId = event?.id;
    const eventPersonId = event?.event_person?.id;
    const reportStatusData = await this.reportExpenseStatus(eventId, eventPersonId, expenseStatusNoExpenses);
    if (reportStatusData) {
      this.events.publish(`update:${pageName}`, {...reportStatusData, eventId});
    }
  }

  async modifyExpense(event, dataForModifyExpense?: any) {
    const eventId = event?.id;
    const personId = event?.event_person?.person_id;
    const artistId = event?.artist?.id;

    const expensesData = {
      eventId,
      personId,
      artistId,
      dataForModifyExpense
    };
    this.navDataService.setNavParams({expensesData, event});
    return this.router.navigate(['/add-expense']);
  }

}
