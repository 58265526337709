import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';


@Injectable()
export class ToastService {

  constructor(
    public translate: TranslateService,
    public toaster: ToastController) {
  }

  /** A helper method for showing a toast.
   * Can display either i18n value for a key, or a given string
   * @param text The text to be shown. Can be i18n key if isI18n = true
   * @param duration the duration to show toast in ms. Default 3000
   * @param isI18n default true whether the text is an i18n key
   */
  async showToast({text, duration = 3000, isI18n = true, color = 'primary', position = 'bottom' as 'top' | 'bottom' | 'middle'}) {
    let toastText: string;

    if (isI18n) {
      // fetch i18n
      this.translate.get(text).subscribe(
        value => toastText = value
      );
    } else {
      toastText = text;
    }

    const toast = await this.toaster.create({
      message: toastText,
      position: position,
      duration,
      color
    });
    await toast.present();
  }
}
