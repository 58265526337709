import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ToastService } from './toast-service';

@Injectable()
export class ResetPasswordService {

  constructor(
    private toastService: ToastService,
    public http: HttpClient,
  ) {
  }

  async resetPasswordLink(resetPassData) {
    const url = environment.baseApiUrl + 'auth/password';

    try {
      const resetPass: any = await this.http.post(url, resetPassData).toPromise();
      if (resetPass.success) {
        await this.toastService.showToast({text: resetPass?.message, duration: 5000, color: 'success', position: 'top'});
        return resetPass;
      }
    } catch (error) {
      await this.toastService.showToast({text: error?.error.errors[0], duration: 5000, color: 'success', position: 'top'});
      return error;
    }
  }
}
