import { Injectable } from '@angular/core';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import * as moment from 'moment';
import 'moment-timezone';

import { AuthService } from './auth-service';

@Injectable({
  providedIn: 'root'
})

export class DateService {

  constructor(
    private authService: AuthService,
    public calendar: Calendar,
  ) {

  }

  currentLanguage() {
    return this.authService.currentLanguage;
  }

  getDateWithYear(date) {
    switch (this.authService.currentLanguage) {
      case 'en':
        return moment(date).format('M/D/YYYY');
      case 'fi':
        return moment(date).format('D.M.YYYY');
      case 'sv':
        return moment(date).format('YYYY-M-D');
    }
  }

  getDateWithoutYear(date) {
    switch (this.authService.currentLanguage) {
      case 'en':
        return moment(date).format('M/D');
      case 'fi':
        return moment(date).format('D.M');
      case 'sv':
        return moment(date).format('M-D');
    }
  }

  getWeekdayShort(day) {
    if (day) {
      return moment(day).format('ddd');
    }
  }

  getWeekdayLong(day) {
    if (day) {
      return moment(day).format('dddd');
    }
  }

  getSchedulesDate(date) {
    switch (this.authService.currentLanguage) {
      case 'en':
        return moment(date).format('M/D/YY');
      case 'fi':
        return moment(date).format('D.M.YY');
      case 'sv':
        return moment(date).format('YY-M-D');
    }
  }

  getSchedulesEventDate(event) {
    return [...new Set(event?.map((el) =>  el?.schedule_date))];
  }

  getSchedulesByDate(events, year) {
    return events?.filter(el => this.getDateWithYear(el.schedule_date) === this.getDateWithYear(year));
  }

  getTime(date) {
    return date ? moment(date).format('HH:mm') : '';
  }

  getYear(year) {
    return moment(year).format('YYYY');
  }

  getToday() {
    var today = new Date().toISOString().slice(0, 10);
    return new Date(today);
  }

  async addToCalendar(eventSchedules, eventData: any) {
    const eventDate = new Date(eventData?.event_date);

    const startEventDate = new Date(
      eventDate.getUTCFullYear(),
      eventDate.getUTCMonth(),
      eventDate.getUTCDate(),
      0,0,0,0);
    const endEventDate = new Date(
      eventDate.getUTCFullYear(),
      eventDate.getUTCMonth(),
      eventDate.getUTCDate() + 1,
      0, 0, 0, 0);

    const minScheduleDate = eventSchedules?.reduce((first, second) => first.starts_at < second.starts_at ? first : second);
    const maxScheduleDate = eventSchedules?.reduce((first, second) => first.starts_at > second.starts_at ? first : second);

    // Parse HTML to calendar note. Remove HTML tags, add line separators.
    let notes = '';

    if (eventData?.artist_info) {
      notes = eventData?.artist_info
        .replace(/<[^>]*>?/gm, '\n')
        .split('\n')
        .filter(el => el !== '')
        .join('\n');
    }

    const startDate = eventSchedules && minScheduleDate.schedule_date <= eventData?.event_date ?
      new Date(minScheduleDate.starts_at) : null;
    const endDate = eventSchedules && maxScheduleDate.schedule_date >= eventData?.event_date ?
      (maxScheduleDate.ends_at ?
        new Date(maxScheduleDate.ends_at) : new Date(new Date(maxScheduleDate.starts_at).getTime() + 2*60*60*1000)) : null;
    // Note! If last schedule does not have end time, let's add 2 hours to start time.

    const eventLocation = eventData?.venue ? eventData?.venue?.name : eventData?.city;

    const calendarData = {
      title: eventData?.artist.artist_with_extension,
      location: eventLocation,
      notes,
      startDate: startDate ? startDate : startEventDate,
      endDate: endDate ? endDate : endEventDate,
      url: eventData?.venue?.google_map_link ? eventData?.venue.google_map_link : null
    };

    await this.calendar.createEventInteractivelyWithOptions(
      calendarData?.title,
      calendarData?.location,
      calendarData?.notes,
      calendarData?.startDate,
      calendarData?.endDate,
      {url: calendarData?.url}
    );
  }

  getDateForCalendar(value, schedule, eventData) {
    let date;
    if (schedule === 'starts_at') {
      date = value.reduce((first, second) => first[schedule] < second[schedule] ? first : second);
    } else if (schedule === 'ends_at') {
      date = value.reduce((first, second) => first[schedule] > second[schedule] ? first : second);
    }
    const d = new Date(date[schedule]);
    date = `${d.getUTCHours()}:${moment(d).format('mm')}`.split(':');
    date = ((+date[0] * 60000 * 60) + (+date[1] * 60000 * 60));
    return new Date(+new Date(eventData?.event_date) + date);
  }

  getDateTimeLocal(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm');
  }

}
