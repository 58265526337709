import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ToastService } from './toast-service';

@Injectable()
export class ScheduleService {

  constructor(
    public http: HttpClient,
    private toastService: ToastService
  ) {
  }

  async fetchScheduleTypeValues() {
    const url = environment.baseApiUrl + `event_schedule_types`;

    try {
      const scheduleTypeValues: any = await this.http.get(url).toPromise();
      return scheduleTypeValues.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async newSchedule(scheduleData) {
    const url = environment.baseApiUrl + `events/${scheduleData.eventId}/event_schedules`;

    const body = {
      event_schedule: {
        event_schedule_type_id: scheduleData.eventScheduleTypeId,
        schedule_type_other: scheduleData.scheduleTypeOther,
        schedule_date: scheduleData.scheduleDate,
        starts_at: scheduleData.startsAt,
        ends_at: scheduleData.endsAt,
        info: scheduleData.info
      }
    };

    try {
      const schedule: any = await this.http.post(url, body).toPromise();
      return schedule.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateSchedule(scheduleData) {
    const url = environment.baseApiUrl + `events/${scheduleData?.eventId}/event_schedules/${scheduleData?.id}`;

    const body = {
      event_schedule: {
        event_schedule_type_id: scheduleData.eventScheduleTypeId,
        schedule_type_other: scheduleData.scheduleTypeOther,
        schedule_date: scheduleData.scheduleDate,
        starts_at: scheduleData.startsAt,
        ends_at: scheduleData.endsAt,
        info: scheduleData.info
      }
    };

    try {
      const updatedSchedule: any = await this.http.put(url, body).toPromise();
      return updatedSchedule.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async deleteSchedule(eventId, id) {
    const url = environment.baseApiUrl + `events/${eventId}/event_schedules/${id}`;

    try {
      await this.http.delete(url).toPromise();
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleError(error) {
    if (error.status === 422) {
      await this.toastService
        .showToast({text: error?.error?.errors[0]?.title, duration: 5000, color: 'warning', position: 'bottom'});
    } else if (error.status === 403) {
      await this.toastService
        .showToast({text: error?.error?.errors[0], duration: 5000, color: 'warning', position: 'bottom'});
    }
  }
}
