import { TranslateService } from '@ngx-translate/core';

export class ValidationService {
  static translate: TranslateService;

  static setTranslationService( translation: TranslateService) {
    ValidationService.translate = translation;
  }

}
