import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class RequestBase {

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  options = {
    headers: this.headers
  };

  constructor(public http: HttpClient) {}

  handleError(error: HttpErrorResponse | any) {
    if (error?.error?.error) {
      return throwError({ message: error?.error?.error, status: error.status });
    }

    let errMsg: string;
    if (error instanceof Response) {
      errMsg = `${error.status} - ${error.statusText || ''}`;
      if (error.status >= 400 && error.status < 500) {
        return throwError({
          errorStatus: error.status,
          message: error.statusText
        });
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError({ message: errMsg, status: error.status });
  }
}
