import { Component, Input, ViewChild } from '@angular/core';
import { IonContent, NavParams, ModalController } from '@ionic/angular';
import { Events } from '../../providers/events';
import { GuestService } from '../../providers/guest-service';
import { guestStateStandby, maxGuestQuota } from '../../app/global';
import { InputsValidationService } from '../../providers/inputs-validation-service';

@Component({
  selector: 'app-add-guest-modal',
  templateUrl: 'add-guest.modal.html',
  styleUrls: ['add-guest.modal.scss']
})

export class AddGuestModalComponent {

  @ViewChild(IonContent) content: IonContent;
  @Input() guestProps: any;

  guest = this.navParams.get('guests');
  customerType = this.guest?.customerType;
  guestType = this.guest?.guestType;
  eventId = this.guest?.eventId;
  firstName = this.guest?.firstName;
  lastName = this.guest?.lastName;
  companyName = this.guest?.companyName;
  guestCount = this.guest?.guestCount;
  updateGuest = this.guest?.updateGuest;
  remainingGuestQuota = this.guest?.remainingGuestQuota;
  guestState = this.guest?.guestState;

  guestsData: any;

  fieldsError = false;
  countError = false;

  constructor(
    private modalControl: ModalController,
    private inputsValidationService: InputsValidationService,
    public navParams: NavParams,
    private guestService: GuestService,
    public events: Events
  ) {}

  ionViewWillEnter() {
    if (this.remainingGuestQuota) {
      this.remainingGuestQuota += this.guestState !== guestStateStandby ? this.guestCount : maxGuestQuota;
    } else {
      this.remainingGuestQuota = maxGuestQuota;
    }
  }

  async createNewGuests() {
    if (this.checkFields()) {
      this.fieldsError = true;
      this.countError = false;
    } else if ((this.guestCount > this.remainingGuestQuota && this.remainingGuestQuota !== 0) && !this.checkFields()) {
      this.fieldsError = false;
      this.countError = true;
    } else {
      this.fieldsError = this.countError = false;
      this.guestsData = await this.guestService
        .newGuest(
          this.eventId,
          this.customerType,
          this.firstName,
          this.lastName,
          this.companyName,
          Number(this.guestCount),
          this.guestState
        );
      if (this.guestsData) {
        this.events.publish('modal:dismissed-add', this.guestCount);
        return await this.modalControl.dismiss();
      }
    }
  }

  async updateGuests() {
    if (this.checkFields()) {
      this.fieldsError = true;
      this.countError = false;
    } else if ((this.guestCount > this.remainingGuestQuota && this.remainingGuestQuota !== 0) && !this.checkFields()) {
      this.fieldsError = false;
      this.countError = true;
    } else {
      this.fieldsError = this.countError = false;
      this.guestsData = await this.guestService
        .updateGuest(this.eventId, this.customerType, this.guest?.id, this.firstName, this.lastName, this.companyName, Number(this.guestCount));
      if (this.guestsData) {
        this.events.publish('modal:dismissed-update', this.guestCount);
        return await this.modalControl.dismiss();
      }
    }
  }

  guestCountValidation() {
    setTimeout(() => {
      this.guestCount = this.inputsValidationService.validateNumberInput(this.guestCount, this.remainingGuestQuota);
    });
  }

  async closeModal() {
    this.remainingGuestQuota -= this.guestCount;
    this.events.publish('modal:cancel');
    return await this.modalControl.dismiss();
  }

  checkFields() {
    if (this.guestType) {
      return !this.companyName || this.companyName?.length < 3 || !this.guestCount;
    } else {
      return !this.firstName || !this.lastName || !this.guestCount;
    }

  }
}
