import { Injectable } from '@angular/core';

@Injectable()
export class NavDataService {

  data: any;

  setNavParams(value: any) {
    this.data = value;
  }

  getNavParams() {
    return this.data;
  }

}
