import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ToastService } from './toast-service';

@Injectable()
export class InquiriesService {

  constructor(
    public http: HttpClient,
    private toastService: ToastService
  ) {
  }

  async fetchInquiries(fetchAnswered: boolean) {
    const url = environment.baseApiUrl + `inquiries`;

    let options = {
      params: {
        answered: fetchAnswered
      }
    };

    if (!fetchAnswered) {
      options['params']['open_for_answer'] = true;
    }

    try {
      const results: any = await this.http.get(url, options).toPromise();
      return results.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleError(error) {
    if (error.status === 422) {
      await this.toastService.showToast({text: error?.error?.errors[0]?.title, duration: 5000, color: 'warning', position: 'bottom'});
    } else if (error.status === 403) {
      await this.toastService.showToast({text: error?.error?.errors[0], duration: 5000, color: 'warning', position: 'bottom'});
    }
  }

}
