import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable()
export class EventService {

  constructor(
    public http: HttpClient,
  ) {
  }

  async fetchEvent(event_id) {
    const url = environment.baseApiUrl + `events/${event_id}`;

    try {
      const events: any = await this.http.get(url).toPromise();
      return events.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

}
