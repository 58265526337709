import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'login',
    loadChildren: () => import('../pages/login-page/login.module').then( m => m.LoginModule) },
  { path: 'tabs',
    loadChildren: () => import('../pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'selected-event/:event_id',
    loadChildren: () => import('../pages/selected-event/selected-event.module').then(m => m.SelectedEventModule) },
  { path: 'add-expense',
    loadChildren: () => import('../pages/expense-page/expense.module').then(m => m.ExpenseModule) },
  { path: 'guest-list',
    loadChildren: () => import('../pages/guest-list-page/guest-list.module').then(m => m.GuestListModule) },
  { path: 'inquiries-question/:inquiry_id',
    loadChildren: () => import('../pages/inquiries-questions/inquiries-questions.module').then(m => m.InquiriesQuestionsModule) },
  { path: 'reset-password',
    loadChildren: () => import('../pages/reset-password-page/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'login-with-token',
    loadChildren: () => import('../pages/login-with-token/login-with-token.module').then(m => m.LoginWithTokenModule), },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
