import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ToastService } from './toast-service';

@Injectable()
export class GuestService {

  constructor(
    public http: HttpClient,
    private toastService: ToastService
  ) {
  }

  async fetchGuest(eventId, personId) {
    const url = environment.baseApiUrl + `events/${eventId}/event_guests.json?for_requester=${personId}`;

    try {
      const guests: any = await this.http.get(url).toPromise();
      return guests.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async newGuest(eventId: number, customerType: number, firstName: string, lastName: string, companyName: string | null, guestCount: number, guestState: number) {
    const url = environment.baseApiUrl + `events/${eventId}/event_guests`;

    const body = {
      event_guest: {
        customer_type_id: customerType,
        first_name: firstName,
        last_name: lastName,
        name: companyName,
        info: '',
        email: '',
        guest_count: guestCount,
        guest_state_id: guestState
      }
    };

    try {
      const guests: any = await this.http.post(url, body).toPromise();
      return guests.data;
    } catch (error) {
      const guestQuotaError = 'addGuestModal.exceededGuestQuotaError';
      if (error.status === 422) {
        await this.toastService
          .showToast({text: guestQuotaError, duration: 5000, color: 'warning', position: 'top'});
      }
      console.log('ERROR:', error);
    }
  }

  async updateGuest(eventId: number, customerType: number, id: number, firstName: string, lastName: string, companyName: string | null,  guestCount) {
    const url = environment.baseApiUrl + `events/${eventId}/event_guests/${id}`;

    const body = {
      event_guest: {
        customer_type_id: customerType,
        first_name: firstName,
        last_name: lastName,
        name: companyName,
        guest_count: guestCount
      }
    }

    try {
      const guests: any = await this.http.put(url, body).toPromise();
      return guests.data;
    } catch (error) {
      const guestQuotaError = 'addGuestModal.exceededGuestQuotaError';
      if (error.status === 422) {
        await this.toastService
          .showToast({text: guestQuotaError, duration: 5000, color: 'warning', position: 'bottom'});
      }
      console.log('ERROR:', error);
    }
  }

  async deleteGuest(eventId, id) {
    const url = environment.baseApiUrl + `events/${eventId}/event_guests/${id}`;

    try {
      await this.http.delete(url).toPromise();
    } catch (error) {
      console.log('ERROR:', error);
    }
  }
}
