import { Injectable } from '@angular/core';

import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private translate: TranslateService,
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).catch(err => this.handleError(err));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    // i18n string
    let incorrectCredentials = '';

    // fetch i18n
    this.translate.get('loginPage.incorrect_credentials').subscribe(
      value => {
        incorrectCredentials = value;
      }
    );
    if (err.status === 401) {
      if (err.url.endsWith('auth/sign_in')) {
        return throwError(incorrectCredentials);
      } else {
        this.authService.redirectToLoginPage();
      }
    }
    this.authService.setAuthConf(err.headers);
    return throwError(err);
  }
}
