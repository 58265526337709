import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthService } from '../providers/auth-service';
import { AuthInterceptor } from '../providers/auth-interceptor';
import { InitializeService } from '../providers/initialize-service';
import { Events } from '../providers/events';
import { EventsService } from '../providers/events-service';
import { RequestInterceptor } from '../providers/request-interceptor';
import { EventService } from '../providers/event-service';
import { DateService } from '../providers/date-service';
import { ExpenseService } from '../providers/expense-service';
import { NavDataService } from '../providers/nav-data-service';
import { AddGuestModalComponent } from '../components/add-guest/add-guest.modal';
import { GuestService } from '../providers/guest-service';
import { DeleteModalComponent } from '../components/delete-modal/delete.modal';
import { ToastService } from '../providers/toast-service';
import { InquiriesService } from '../providers/inquiries-service';
import { InquiriesQuestionService } from '../providers/inquiries-question-service';
import { HomeService } from '../providers/home-service';
import { SettingsService } from '../providers/settings-service';
import { PlatformService } from '../providers/platform-service';
import { ResetPasswordService } from '../providers/reset-password-service';
import { InputsValidationService } from '../providers/inputs-validation-service';
import { AddScheduleModalComponent } from '../components/add-schedule/add-schedule.modal';
import { ScheduleService } from '../providers/schedule-service';
import { ServiceWorkerModule } from '@angular/service-worker';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AddGuestModalComponent,
    DeleteModalComponent,
    AddScheduleModalComponent
  ],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FormsModule,
    PdfViewerModule,
    ImageCropperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HttpClient,
    HttpClientModule,
    AuthService,
    TranslateService,
    InitializeService,
    Events,
    EventsService,
    EventService,
    DateService,
    ExpenseService,
    NavDataService,
    GuestService,
    ToastService,
    InquiriesService,
    InquiriesQuestionService,
    HomeService,
    SettingsService,
    PlatformService,
    ResetPasswordService,
    InputsValidationService,
    Calendar,
    ScheduleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      useFactory(authService: AuthService) {
        return new RequestInterceptor(authService);
      },
      multi: true,
      deps: [AuthService]
    },
    { provide: HTTP_INTERCEPTORS,
      useFactory(translate: TranslateService, authService: AuthService) {
        return new AuthInterceptor(translate, authService);
      },
      multi: true,
      deps: [TranslateService, AuthService] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
