// App default language
export const appDefaultLang = 'fi';

// Guest constants
export const guestStateNew        = 1;
export const guestStateConfirmed  = 2;
export const guestStateStandby    = 3;
export const guestStateDeclined   = 4;

export const maxGuestQuota = 100;

// Expense constants
export const expenseStatusNoExpenses  = 1;
export const expenseStatusHasExpenses = 2;

export const expenseStateBudgeted = 1;
export const expenseStateReceived = 2;
export const expenseStateAdmitted = 3;
export const expenseStatePaid = 4;
export const expenseStateRejected = 5;

// Inquiries
export const inquiriesOpen = 'open';
export const inquiriesAnswered = 'answered';

// App version, as shown on the settings
export const appVersion = '1.2';
export const buildNumber = 1003;
export const versionNumber = appVersion + ' (' + buildNumber.toString() + ')';

// App languages
export const languageEnglishID = 39;
export const languageFinnishID = 44;
export const languageSwedishID = 139;

export const SUPPORTED_LANGUAGES = [
  { key: 'fi', id: languageFinnishID },
  { key: 'en', id: languageEnglishID },
  { key: 'sv', id: languageSwedishID },
];

// Password validator pattern
// (?=.*[A-Z])  use positive look ahead to see that at least one upper case letter exists
// (?=.*[a-z])  use positive look ahead to see that at least one lower case letter exists
// (?=.*\d)     use positive look ahead to see that at least one digit exists
// .{8,}        check that at least 8 letters exist
export const passwordValidatorsPattern = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}/;

// Email field validator
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Schedule type
export const eventScheduleTypeOther = 999;
