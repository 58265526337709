import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { expenseStateReceived } from '../app/global';

@Injectable()
export class ExpenseService {

  url: string;

  constructor(
    public storage: Storage,
    public http: HttpClient,
    public authService: AuthService
  ) {

  }

  async fetchCostTypes(expense) {
    const url = environment.baseApiUrl + `cost_types?for_expenses=${expense}&without_per_diem=${expense}`;

    try {
      const expenseCostTypes: any = await this.http.get(url).toPromise();
      return expenseCostTypes.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async fetchPreviousExpenses(eventId, personId) {
    const url = environment.baseApiUrl + `events/${eventId}/expenses?by_person=${personId}`;

    try {
      const expenseData: any = await this.http.get(url).toPromise();
      return expenseData.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }
  async saveExpenses(eventId, personId, costTypeId, artistId, message, photo, updateExpense, expenseId) {

    this.url = environment.baseApiUrl + `events/${eventId}/expenses`;

    const body = {
      expense: {
        cost_type_id: costTypeId,
        info: message.trim(),
      }
    };

    if (updateExpense) {
      this.url = this.url + `/${expenseId}`;
    } else {
      body.expense = Object.assign({
          ...body.expense,
          expense_state_id: expenseStateReceived,
          person_id: personId,
          artist_id: artistId,
          unit_price: 0,
          amount: 0,
          total_amount: 0
        });
    }

    if (photo?.data) {
      body.expense = Object.assign({...body.expense, receipt: photo});
    }

    try {
      if (updateExpense) {
        const expenseData: any = await this.http.put(this.url, body).toPromise();
        return expenseData.data;
      } else {
        const expenseData: any = await this.http.post(this.url, body).toPromise();
        return expenseData.data;
      }
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async deleteExpense(eventId, id) {
    const url = environment.baseApiUrl + `events/${eventId}/expenses/${id}`;

    try {
      await this.http.delete(url).toPromise();
    } catch (error) {
      console.log('ERROR:', error);
    }
  }
}
