import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ToastService } from './toast-service';

@Injectable()
export class InquiriesQuestionService {

  constructor(
    public http: HttpClient,
    private toastService: ToastService
  ) {
  }

  async fetchInquiriesQuestions(inquiryId) {
    const url = environment.baseApiUrl + `inquiries/${inquiryId}/questions`;

    try {
      const rows: any = await this.http.get(url).toPromise();
      return rows.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async saveInquiriesQuestions(questions, inquiryId: number) {
    const url = environment.baseApiUrl + `inquiries/${inquiryId}/questions/${questions.questionId}/responses`;

    const body = {
      inquiry_person_response: questions.body
    };

    try {
      const events: any = await this.http.post(url, body).toPromise();
      return events.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async changeInquiriesQuestions(questions, inquiryId: number) {
    const url = environment.baseApiUrl + `inquiries/${inquiryId}/questions/${questions.questionId}/responses/${questions.responsesId}`;

    const body = {
      inquiry_person_response: questions.body
    };

    try {
      const events: any = await this.http.put(url, body).toPromise();
      return events.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleError(error) {
    if (error.status === 422) {
      await this.toastService.showToast({text: error?.error?.errors[0]?.title, duration: 3000, color: 'warning', position: 'bottom'});
    } else if (error.status === 403) {
      await this.toastService.showToast({text: error?.error?.errors[0], duration: 5000, color: 'warning', position: 'bottom'});
    }
  }

}
