import { Injectable } from '@angular/core';
import { DeviceInfo, Device, DeviceId } from '@capacitor/device';

@Injectable()
export class PlatformService {
  platform: string;
  version: string;
  service = 'apns2';
  info: DeviceInfo;
  deviceId: DeviceId;

  constructor() {
    this.getPlatform();
  }

  async getPlatform() {
    this.info = await Device.getInfo();
    this.deviceId = await Device.getId();
    this.platform = this.info.platform;
    this.version = this.info.osVersion;

    if (this.platform === 'android') {
      this.service = 'fcm';
    }
    // TODO: Add push notifications for PWA.
    // if (this.platform === 'web') {
    //   this.service = 'webpush';
    // }
  }

  isIos() {
    return this.platform === 'ios';
  }

  isWeb() {
    return this.platform === 'web';
  }
}
