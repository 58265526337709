import { Component, Input, ViewChild } from '@angular/core';
import { IonContent, NavParams, ModalController } from '@ionic/angular';

import { Events } from '../../providers/events';
import { GuestService } from '../../providers/guest-service';
import { ExpenseService } from '../../providers/expense-service';
import { ScheduleService } from '../../providers/schedule-service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: 'delete.modal.html',
  styleUrls: ['delete.modal.scss']
})

export class DeleteModalComponent {

  @ViewChild(IonContent) content: IonContent;
  @Input() guestProps: any;

  id = this.navParams.get('elementId');
  eventId = this.navParams.get('eventId');
  guestCount = this.navParams.get('guestCount');
  position = this.navParams.get('position');
  itemName = this.navParams.get('deleteItemName');
  elementWidth = this.navParams.get('elementWidth');

  constructor(
    private modalControl: ModalController,
    public navParams: NavParams,
    private guestService: GuestService,
    private expenseService: ExpenseService,
    private scheduleService: ScheduleService,
    public events: Events
  ) {
  }

  ionViewWillLeave(): void {}

  ionViewWillEnter() {
    const elementByClass = document.getElementsByClassName('delete-items-modal')[0]['style'];
    elementByClass.top=this.position.top + 'px';
    if (this.itemName === 'schedule') {
      if (document.body.offsetWidth >= 370) {
        elementByClass.left='calc(50% - 185px)';
      } else {
        elementByClass.left = 0;
      }
    } else {
      elementByClass.left=this.position.left + 'px';
    }
  }

  async delete() {
    if (this.itemName === 'expense') {
      await this.expenseService.deleteExpense(this.eventId, this.id);
    } else if (this.itemName === 'schedule') {
      await this.scheduleService.deleteSchedule(this.eventId, this.id);
    } else {
      await this.guestService.deleteGuest(this.eventId, this.id);
    }
    await this.dismissModal();
  }

  async dismissModal() {
    this.events.publish('modal:dismissed-delete', { itemName: this.itemName, guestCount: this.guestCount });
    return await this.modalControl.dismiss();
  }

  async closeModal() {
    this.events.publish('modal:cancel');
    return await this.modalControl.dismiss();
  }
}
