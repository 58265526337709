import { Injectable } from '@angular/core';

@Injectable()
export class InputsValidationService {

  constructor() {}

  validateNumberInput(value: string, maxAllowedNumber: number) {
    let maxIntegerValue;
    const integerValueNumber = Number(value);

    if (value?.length === 1 && value === '0') {
      return '0';
    } else if (value?.length > 1 && value[0] === '0') {
      return value?.slice(1);
    } else if (integerValueNumber > maxAllowedNumber || !value?.match(/^[1-9][0-9]{0,4}$/)) {
      if (
        isNaN(integerValueNumber) ||
        !value.match(/^[0-9]*$/)
      ) {
        maxIntegerValue = value?.split('')?.filter(el => el.match(/^[0-9]$/)).join('');
      } else {
        maxIntegerValue = value?.slice(0, -1);
      }
      return maxIntegerValue;
    }
    return value;
  }
}
