import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { PushNotifications, Token } from '@capacitor/push-notifications';

import { AuthService } from './auth-service';

@Injectable()
export class InitializeService {

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private authService: AuthService,
  ) {}

  async initializeApp() {
    const authHeaders = await this.authService.getAuthHeaders();
    return !!authHeaders;
  }
  async initPushNotification() {
    // No push notifications unless native build
    if (!this.platform.is || !this.platform.is('capacitor') || this.authService.deviceToken) {
      return;
    }

    // Request Push Notification permission.
    const request = await PushNotifications.requestPermissions();
    if (request?.receive === 'granted') {
      // The user has granted permission to register to APNS/FCM.
      PushNotifications.register();
    } else {
      // The user has rejected our request to register with APNS/FCM.
    }

    // On successful registration, the device will send us the Device Token.
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('Push registration success! Device Token: ' + token.value);
        this.authService.deviceToken = token.value;
      }
    );

    // If registration fails, alert the error message.
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error registering for Push: ' + JSON.stringify(error));
      }
    );
  }

  // TODO: Add push notifications for PWA
}
