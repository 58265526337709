import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { ValidationService } from '../providers/validation-service';
import { AuthService } from '../providers/auth-service';
import { InitializeService } from '../providers/initialize-service';

import { appDefaultLang } from './global';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private authService: AuthService,
    private initializeService: InitializeService,
    private router: Router,
  ) {
    ValidationService.setTranslationService(translate);
    this.platform.ready().then((data) => {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.show();
      }

      // Set last used language if exists else set app default language
      this.authService.getLastUsedLanguage()
        .then((lang) => {
          if (lang !== null && typeof (lang) !== 'undefined') {
            this.translate.setDefaultLang(lang);
            this.authService.currentLanguage = lang;
            moment.locale(lang);
          } else {
            this.authService.setLastUsedLanguage(appDefaultLang);
            this.translate.setDefaultLang(appDefaultLang);
            this.translate.use(appDefaultLang);
            moment.locale(appDefaultLang);
          }
        });
      this.initializeApp();
    });
  }

  async initializeApp() {
    await this.initializeService.initPushNotification();
    await this.authService.getAuthHeaders();
    await this.authService.getUserInfo();
    await this.authService.getUserRedirectUrl();
    await this.checkUserCredentials();
  }


  async checkUserCredentials() {
    const isAppInitialized = await this.initializeService.initializeApp();

    this.authService.fetchSavedCredentials()
      .then((res: boolean | undefined) => {
        if (res && isAppInitialized && !this.router.url.includes('/selected-event/')) {
          this.router.navigate(['/tabs/home']);
        } else if (this.router.url.startsWith('/reset-password')) {

        } else if (!isAppInitialized || !res) {
          this.router.navigate(['/']);
        }
      });
  }
}
