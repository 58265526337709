import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ToastService } from './toast-service';

@Injectable()
export class SettingsService {

  constructor(
    private toastService: ToastService,
    public http: HttpClient,
  ) {
  }

  async fetchSettingsData() {
    const url = environment.baseApiUrl + 'settings';

    try {
      const settings: any = await this.http.get(url).toPromise();
      return settings.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async changeProfile(id, userData) {
    const url = environment.baseApiUrl + `users/${id}`;

    try {
      const profile: any = await this.http.put(url, userData).toPromise();
      return profile.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async changeUserLanguage(id, languageID) {
    const url = environment.baseApiUrl + `users/${id}`;

    const body = {
      language_id: languageID
    };

    try {
      const language: any = await this.http.put(url, body).toPromise();
      return language.data;
    } catch (error) {
      console.log('ERROR:', error);
    }
  }

  async changePassword(changePassword) {
    const url = environment.baseApiUrl + 'auth/password';

    try {
      const password: any = await this.http.put(url, changePassword).toPromise();
      if (password) {
        await this.toastService.showToast({text: password?.message, duration: 5000, color: 'success', position: 'top'});
        return password.data;
      }
    } catch (error) {
      console.log('ERROR:', error);
    }
  }
}
