import {Injectable} from '@angular/core';
import {Device, DeviceId} from '@capacitor/device';
import {HttpClient} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {omit} from 'lodash';
import {Router} from '@angular/router';

import {RequestBase} from './request-base';
import {appVersion, buildNumber} from '../app/global';
import {environment} from '../environments/environment';
import { emailRegex } from '../app/global';

const AUTH_SERVICE_PROPERTIES = [
  'authConfig', 'userInfo'
];

@Injectable()
export class AuthService extends RequestBase {
  public uid: string;
  public clientId: number;
  public firstName: string;
  public stateId: number;
  public client: string;
  public accessToken: string;
  public expiryValue: string;
  public authHeaders: object;
  public expiry: number;
  public userInfoData: any;

  public lastUsedLang: string;
  public currentLanguage: string;

  deviceId: DeviceId;
  public deviceToken: string;
  public routerUrl = null;

  constructor(
    private storage: Storage,
    private router: Router,
    public http: HttpClient
  ) {
    super(http);
  }

  async login({email, password, deviceInfo, deviceId}) {
    const url =  environment.baseApiUrl + `auth/sign_in`;

    const userAgent = window.navigator.userAgent;

    const body = {
      email,
      password,
      device_uuid: deviceId?.identifier,
      device_name: deviceInfo?.name,
      device_model: deviceInfo?.model,
      device_platform: deviceInfo?.platform,
      device_os: deviceInfo?.operatingSystem,
      device_os_version: deviceInfo?.osVersion,
      device_manufacturer: deviceInfo?.manufacturer,
      device_token: this.deviceToken,
      user_agent: userAgent,
      app_version: appVersion,
      build_number: buildNumber,
    };

    const user: any = await this.http.post(url, body).toPromise();
    await this.setUserInfo(user.data);
    return user.data;
  }

  async logout() {
    this.deviceId = await Device.getId();

    const url =  environment.baseApiUrl + `auth/sign_out` + `?device_uuid=` + this.deviceId?.identifier;

    return this.http.delete(url).toPromise();
  }

  getLastUsedLanguage() {
    return this.storage.create()
      .then( async () => await this.storage.get('lastUsedLang'));
  }

  setLastUsedLanguage(lang: string) {
    this.lastUsedLang = lang;
    this.currentLanguage = lang;
    return this.storage.create()
      .then(() =>
        this.storage.set('lastUsedLang', lang)
      );
  }

  async fetchSavedCredentials() {
    await this.storage.create();
    return await Promise.all(AUTH_SERVICE_PROPERTIES.map(async (property: string) => {
      this[property] = await this.storage.get(property);
    })).then(() => true);
  }

  clearSavedCredentials() {
    AUTH_SERVICE_PROPERTIES.forEach((property: string) => this.storage.set(property, null));
  }

  /**
   * Set auth configuration data from headers to service store and ionic store.
   * @param {Object} headers
   */
  setAuthConf(headers) {
    const accessToken = headers.get('access-token');

    if (!accessToken) {
      return;
    }

    this.authHeaders = {
      uid: headers.get('uid'),
      client: headers.get('client'),
      'access-token': accessToken
    };

    /*
      Convert expiry timestamp to ms from epoch.
     */
    this.expiry = headers.get('expiry') * 1000;

    // ignore promise deliberately, we do not need to wait it here
    this.storage.set('authConfig', {...this.authHeaders, ...{expiry: this.expiry}});
  }

  /**
   * Load auth configuration data from ionic store to service store.
   * @private
   */
  private async _loadAuthConf() {
    const authConfig = await this.storage.get('authConfig');
    if (!authConfig) {
      return;
    }
    this.expiry = authConfig.expiry;
    this.authHeaders = omit(authConfig, ['expiry']);
  }

  /**
   * Get auth headers with lazy loading from ionic store.
   * @returns {Object}
   */
  async getAuthHeaders() {
    if (!this.authHeaders) {
      await this._loadAuthConf();
    }
    return this.authHeaders;
  }

  /**
   * Get expiry timestamp with lazy loading from ionic store.
   * @returns {Number}
   */
  async getExpiry() {
    if (!this.expiry) {
      await this._loadAuthConf();
    }
    return this.expiry;
  }

  async setUserInfo(userInfo) {
    let user = omit(userInfo, ['provider', 'uid']);
    this.userInfoData = user;
    await this.storage.set('userInfo', user);
  }

  async getUserInfo() {
    const userInfo = await this.storage.get('userInfo');

    if (!userInfo) {
      return;
    }

    this.userInfoData = userInfo;
  }

  async redirectToLoginPage() {
    this.clearSavedCredentials();
    return await this.router.navigate(['/']);
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(emailRegex);
  };

  async getUserRedirectUrl() {
    if (this.router.url !== '/' && this.router.url !== '/login') {
      this.routerUrl = this.router.url;
    }
  }
}
