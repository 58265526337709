import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import {from, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { AuthService } from './auth-service';

/**
 * Headers which should be included to each request.
 */
const STANDARD_HEADERS = {
  'Accept-Language': '*',
  'accept': '*/*',
  'Content-Type':  'application/json'
};

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService
  ) {}

  // handle pre-request
  async handleAuth(req: HttpRequest<any>, next: HttpHandler) {
    const authHeaders = await this.authService.getAuthHeaders();
    const expiry = await this.authService.getExpiry();
    const now = Date.now();

    if (now > expiry) {
      await this.authService.redirectToLoginPage();
    }

    const authReq = req.clone({
      headers: new HttpHeaders({
        ...STANDARD_HEADERS,
        ...authHeaders
      }),
      setParams: {
        locale: this.authService.currentLanguage,
      },
      params: req.method.toUpperCase() === 'GET' ? req.params.set('cachebreak', now) : req.params
    });

    return await next.handle(authReq).toPromise();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url && req.url.startsWith('http')) {
      // ignore sign in request
      if (req.url.endsWith('auth/sign_in')) {
        const authReq = req.clone({
          headers: new HttpHeaders(STANDARD_HEADERS)
        });
        return next.handle(authReq).pipe(tap(event => {
          if (event instanceof HttpResponse) {
            this.authService.setAuthConf(event.headers);
          }
        }));
      }
      return from(this.handleAuth(req, next))
        .pipe(tap(event => {
          if (event instanceof HttpResponse) {
            this.authService.setAuthConf(event.headers);
          }
        }));
    }
    // ignore not network requests, like json files reading for i18n
    else {
      return next.handle(req);
    }
  }
}
